import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Flex } from 'rebass/styled-components';
import Container from '../../atoms/Container';
import Footer from '../../atoms/Footer';
import { wlcGetroLogoClicked } from '../../../redux/actions/layout';
import { hasAdvancedDesignSelector, networkLegalSelector } from '../../../redux/selectors/network';
import { NetworkSchema } from '../../../schema/network';
import usePrivacyPolicyUrl from '../../../hooks/usePrivacyPolicyUrl';

const NoHero = ({ network, children }) => {
  const hasAdvancedDesign = hasAdvancedDesignSelector(network);
  const networkLegal = networkLegalSelector(network);
  const onLogoClick = () => dispatch(wlcGetroLogoClicked({ network, origin: 'powered-by-footer' }));
  const { privacyPolicyUrl, isExternal: isExternalPrivacyPolicyUrl } = usePrivacyPolicyUrl(network);

  const dispatch = useDispatch();
  const { name: networkName } = network || {};

  useEffect(() => {
    const node = document.querySelector('#main-wrapper');
    if (node) {
      node.classList.add('getro-bg');
    }
    return function cleanup() {
      if (node) {
        node.classList.remove('getro-bg');
      }
    };
  }, []);

  return (
    <Flex
      bg="white"
      minHeight="100vh"
      flexDirection="column"
      alignItems="stretch"
      id="wlc-main"
      className="network-container"
      data-testid="no-hero-layout"
    >
      <Container id="content" display="flex" flexGrow={1} mt={5}>
        {children}
      </Container>
      {network?.id && (
        <Footer
          onLogoClick={onLogoClick}
          hasAdvancedDesign={hasAdvancedDesign}
          networkName={networkName}
          networkLegal={networkLegal}
          isExternalPrivacyPolicyUrl={isExternalPrivacyPolicyUrl}
          privacyPolicyUrl={privacyPolicyUrl}
        />
      )}
    </Flex>
  );
};

NoHero.propTypes = {
  children: PropTypes.node.isRequired,
  network: NetworkSchema,
};

NoHero.defaultProps = {
  network: null,
};

export default NoHero;
