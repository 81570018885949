import React from 'react';
import { Flex, Text, Box, Link } from 'rebass/styled-components';
import { Logo } from '@getro/rombo';
import SEO from '../../components/molecules/Seo';
import NoHero from '../../components/layouts/noHero';
import { NetworkSchema } from '../../schema/network';
const NotFound = ({ network = {} }) => (
  <Flex data-testid="page" justifyContent="center" width={[1]} alignItems="center">
    <SEO network={network} title="Oops! We can't find that page" />
    <Flex width={[1, 0.3]} mx="auto" flexDirection="column" mt={[5, 0]}>
      <Box mb={2} as={Logo} width="120px" />
      <Text mt={4} mb={3} fontSize={4} fontWeight="bold" color="dark">
        Oops! We can&apos;t find that page
      </Text>
      <Text fontSize={2} mb={3}>
        It looks like something went wrong. We have tracked the issue and will fix it as soon as possible.
      </Text>
      <Text fontSize={2}>
        Please try reloading the page or{' '}
        <Link variant="getroLink" href="mailto:help@getro.com" target="_blank" rel="noopener noreferrer">
          contact us
        </Link>
        .
      </Text>
    </Flex>
  </Flex>
);

NotFound.propTypes = {
  network: NetworkSchema,
};

NotFound.defaultProps = {
  network: null,
};

NotFound.Layout = NoHero;

export const getServerSideProps = async ({ res }) => {
  res.statusCode = 404;
  return {
    props: {},
  };
};

export default NotFound;
